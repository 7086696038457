<!--
--------------------------------------------------------------------------------
<copyright file="LaserStatus.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.laser_current_state') }}</summary>
      <p class="text">{{ $t('widget_info_text.txt_color_display') }}</p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold"> {{ $t('widget_info_text.overview_view') }}:</span>
          {{ $t('widget_info_text.overview_view_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold"> {{ $t('widget_info_text.machine_view') }}:</span>
          {{ $t('widget_info_text.machine_view_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold"> {{ $t('widget_info_text.color_code') }}:</span>
          <ul class="list">
            <li class="list-item2">
              <span class="work">
                {{ $t('widget_info_text.green') }} - {{ $t('widget_info_text.work') }}</span
              >: {{ $t('widget_info_text.green_txt') }}<br />
            </li>
            <li class="list-item2">
              <span class="idle">
                {{ $t('widget_info_text.light_orange') }} - {{ $t('widget_info_text.idle') }}</span
              >: {{ $t('widget_info_text.lOrange_txt') }}<br />
            </li>
            <li class="list-item2">
              <span class="stop">
                {{ $t('widget_info_text.dark_orange') }} - {{ $t('widget_info_text.stop') }}</span
              >: {{ $t('widget_info_text.dOrange_txt') }}<br />
            </li>
            <li class="list-item2">
              <span class="wait">
                {{ $t('widget_info_text.brown') }} - {{ $t('widget_info_text.wait') }}</span
              >: {{ $t('widget_info_text.brown_txt') }}<br />
            </li>
            <li class="list-item2">
              <span class="error">
                {{ $t('widget_info_text.red') }} - {{ $t('widget_info_text.error') }}</span
              >: {{ $t('widget_info_text.red_txt') }}<br />
            </li>
          </ul>
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.laser_health') }}</summary>
      <ul class="list">
        <li class="list-item">
          <p class="text">{{ $t('widget_info_text.machine_health_txt') }}</p>
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.operating_hours') }}</summary>
      <ul class="list">
        <li class="list-item">
          <p class="text">{{ $t('widget_info_text.operating_hours_txt') }}</p>
        </li>
      </ul>
      <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
      <p class="text info-bkg">{{ $t('widget_info_text.operating_hours_info_txt_cut') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.laser_power') }}</summary>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold"> {{ $t('widget_info_text.peak_power') }}:</span>
          {{ $t('widget_info_text.peak_power_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold"> {{ $t('widget_info_text.average_power') }}:</span>
          {{ $t('widget_info_text.average_power_txt') }}
        </li>
      </ul>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class LaserStatusInfo extends Vue {}
</script>
